<template>
	<div class="main">
		<el-container>
				<MyHeader :userName="userName"></MyHeader>
			<div class="bo"></div>
			<Nav></Nav>
			<el-main>
				<div class="lorder">
					<div class="latestOrders" align="left">
						<div style="display: flex;justify-content: space-between;">
							<div style="font-size: 1.5rem;color:#2B9BB3;font-weight: bolder;font-family: '微软雅黑';">最近订单</div>
							<div class="btns" align="left">
								<button class="addneworder" @click="addNewOrder">添加新订单</button>
								<button class="getallorder" @click="allOrder">查看所有订单</button>
							</div>
						</div>
						
						<div class="tablescon">
							<el-table height="350" :data="tableData" stripe style="width: 100%;">
								<el-table-column prop="orderNumber" label="订单ID号" width="180">
									<template slot-scope="scope">
										<span v-if="scope.row.orderNumber==null">暂无</span>
									</template>
								</el-table-column>
								<el-table-column prop="oid" label="订单ID号" width="180" v-if="false">
								</el-table-column>
								<el-table-column prop="patientNum" label="患者编号" width="180">
								</el-table-column>
								<el-table-column prop="patientNum" label="订单类型">
								</el-table-column>
								<el-table-column prop="productId" label="产品类型">
									<template slot-scope="scope">
										<span v-if="scope.row.productId==1">瓠犀</span>
										<span v-if="scope.row.productId==2">优诗美</span>
									</template>
								</el-table-column>
								<el-table-column prop="status" label="订单状态">
									<template slot-scope="scope">
										<span v-if="scope.row.status==0">创建(未完成)</span>
										<span v-if="scope.row.status==1">未审核</span>
										<span v-if="scope.row.status==2">审核未通过</span>
										<span v-if="scope.row.status==3">已确认,待付款</span>
										<span v-if="scope.row.status==4">已付款,制作中</span>
										<span v-if="scope.row.status==5">运输中</span>
										<span v-if="scope.row.status==6">已完成</span>
									</template>
								</el-table-column>
								<el-table-column prop="updatedTime"  width="180" label="提交日期">
								</el-table-column>
								<el-table-column  label="操作">
									<template slot-scope="scope">
										<a v-if="scope.row.status==0||scope.row.status==1" href="javascript:void(0)"
											@click="handleDelete(scope.$index, scope.row)">删除</a>&nbsp;
										<a href="javascript:void(0)" @click="lookOrder(scope.row)">查看</a>&nbsp;
										<a v-if="scope.row.status==0||scope.row.status==1||scope.row.status==2" href="javascript:void(0)" @click="editOrder(scope.row)">编辑</a>
									</template>
								</el-table-column>
							</el-table>		
						</div>		
					</div>
					<!-- 饼状图 -->
					<!-- <div class="chart">
						<div id="myChart" :style="{width: '370px', height: '280px'}">111</div>
					</div> -->
				</div>
					
				
			</el-main>
			<el-footer style="font-size: 0.875rem; color: #1196AB;">
				Copyright © www.techlion,.com.cn All Rights Reserved.<br>
				山东泰克莱恩 8008888888
			</el-footer>
		</el-container>

<el-dialog title="产品类型" :visible.sync="dialogFormVisible">
  <el-form :model="form" :inline="true">
    <el-form-item label="产品类型">
      <el-select v-model="form.productId" placeholder="请选择产品类型">
        <el-option label="瓠犀" value="1"></el-option>
        <el-option label="优诗美" value="2"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="selProductType">确 定</el-button>
    </div>
  </el-dialog>
	</div>
</template>
<!-- 订单状态：0:创建（未完成），1:未审核，2:审核未通过，3:已确认，待付款，4:已付款，制作中，5:运输中，6:已完成 -->
<script>
	import MyHeader from '../components/MyHeader.vue'
	import Nav from '../components/Nav.vue'
	export default {
		
		data: function() {
			return {
				//chartColumn: null, //柱状图
				loginUserName: '张三',
				tableData: [],
				//tongjiorder:[],
				userName:'',
				doctorId:'0',
				dialogFormVisible:false,
				form:{
					productId:''
				},
				formLabelWidth:'120px'
			}
			
		},
		components:{
			MyHeader,
			Nav
		},
		methods: {
			//查询最近订单
			getLatestOrder(){
				this.$axios.get("client/order/initOrderListLimit",{
					params:{
						doctorId:this.doctorId
					}
				}).then(res=>{
					console.log(res.data);
					this.tableData=res.data.data;
				}).catch(err=>{
					console.log(err);
				});
			},
			//确定产品类型(选择优诗美还是瓠犀)
			selProductType(){
				if(this.form.productId==1){
					this.$router.push("/addOrder/orderone");
				}else{
					
				}
				this.dialogFormVisible=false;
			},
			//设置饼状图
			// drawLine() {
			// 	var _this=this;
			// 	this.$axios.get("./tongjiorder.json").then(res=>{
			// 		this.tongjiorder=res.data;
			// 		console.log(this.tongjiorder);
			// 		console.log(this.$axios);
			// 		this.chartColumn = this.$echarts.init(document.getElementById('myChart'));
			// 		this.chartColumn.setOption({
			// 			title: {
			// 				text: '订单完成情况',
			// 				subtext: '',
			// 				left: 'center'
			// 			},
			// 			tooltip: {
			// 				trigger: 'item'
			// 			},
			// 			legend: {
			// 				orient: 'vertical',
			// 				left: 'left'
			// 			},
			// 			series: [{
			// 				name: 'Access From',
			// 				type: 'pie',
			// 				radius: '50%',
			// 				data:_this.tongjiorder,
			// 				emphasis: {
			// 					itemStyle: {
			// 						shadowBlur: 10,
			// 						shadowOffsetX: 0,
			// 						shadowColor: 'rgba(0, 0, 0, 0.5)'
			// 					}
			// 				}
			// 			}]
			// 		});
			// 	}).catch(err=>{
			// 		console.log(err);
			// 	});
			// },
			//删除
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			//添加新订单
			addNewOrder(){
				this.dialogFormVisible=true;
				//this.$router.push("/addOrder/orderone");
			},
			//编辑订单
			editOrder(row){
				console.log(row);
				//将当前要编辑的订单oid存储到sessionStorage中
				sessionStorage.setItem("oid",row.oid)
				this.$router.push("/addOrder/orderone");
			},
			//查看具体某个订单
			lookOrder(row){
					sessionStorage.setItem("oid",row.oid);
					this.$router.push("/lookorder");
			},
			//删除订单
			handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get("/client/order/delOrder",{
						params:{
							oid:row.oid
						}
					}).then(res=>{
						if(res.data.code==200){
							this.tableData.splice(index, 1);
							this.$message({
								type: 'success',
								message: '删除成功!',
								duration: 900
							});
						}else{
							this.$message({
								type: 'error',
								message: '删除失败',
								duration: 900
							});
						}
					}).catch(err => {
						console.log(err);
						this.$message({
							type: 'error',
							message: '请联系管理员',
							duration: 900
						});
					});
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
						duration: 900
					});
				});
			
			},
			//查看所有订单
			allOrder(){
				this.$router.push("/allorder");
			}
		},
		mounted:function(){
			//获取产品id
			console.log("产品id:"+this.$route.query.product_id);
			//加载最新订单
			this.getLatestOrder();
			//画饼状图
			//this.drawLine();
			//获取当前登录的用户名
			let dname=JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			let doctorId=JSON.parse(sessionStorage.getItem("loginInfo")).id;
			this.userName=dname;
			this.doctorId=doctorId;
			//设置location
			sessionStorage.setItem("location","orderone");
			sessionStorage.removeItem("oid");
		}
	}
</script>

<style scoped="scoped">
	.main {
		width: 100%;
		height: 100%;
		/* background-image: linear-gradient(to bottom, white 20%, #58C9D5, #1196AB); */
		 /* background-image:linear-gradient(to bottom,white 20%, #9BEDEB, #28D9D5); */
		 background-color: whitesmoke;
	}

	.el-container {
		height: 100%;
	}

	.el-header {
		line-height: 3.75rem;
		background-image: url(../assets/logo@2x.png);
		background-repeat: no-repeat;
		background-position: 1.25rem 0.625rem;
		display: flex;
		justify-content: flex-end;
		/* align-content: center; */
		/* align-items: center; */
		align-items: center;
	}

	.bo {
		width: 100%;
		height: 0.1125rem;
		background-image: linear-gradient(to right, #58C9D5);
	}

	.header1,
	.header2,
	.header3,
	.header4 {
		display: inline-block;
		margin-left: 1.25rem;
	}

	.header4 {
		height: 2.1875rem;
	}

	.loginName {
		color: #2B9BB3;
		font-weight: bold;
	}


	/* 最近订单 */
	.latestOrders {
		width: 95%;
		margin: 0px auto;
		margin-top: 1.25rem;
	}

	tbody {
		background-color: red !important;
	}
	.lorder{
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-image: linear-gradient(to bottom,white,lightgray);
		border-radius: 0.9375rem;
		width: 80%;
		height: 100%;
		margin: 0px auto;
		padding-bottom: 0.625rem;
	}
	
	/* 添加订单 */
	.btns{
		padding-left: 3.7rem;
		padding-right: 0.6125rem;
		margin-bottom: 0.3125rem
	}
	.btns .addneworder,.btns .getallorder{
		background-color: whitesmoke;
		/* background-image: linear-gradient(to right,white,#28D9D5); */
		background-color:whitesmoke;
		border:none;
		height: 1.875rem;
		width: 10.5rem;
		margin-left: 0.3125rem;
		border-radius: 0.3125rem;
		color: #2B9BB3;
		font-weight: bolder;
	}
	.chart{
		height: 20rem;
		width: 30%;
		display: flex;
		justify-content: center;
		align-items:flex-end;
	}
	.tablescon{
		margin-top: 0.625rem;
		height: 80%;
	}
	.el-main::-webkit-scrollbar {
		display: none;
	}
	
</style>
